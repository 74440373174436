import { Entity } from '@backstage/catalog-model';
import { ConfigApi } from '@backstage/core-plugin-api';
import { EntityTextFilter } from '@backstage/plugin-catalog-react';

export class EntitySearchTextFilter extends EntityTextFilter {
  constructor(value: string, _: ConfigApi) {
    super(value);
  }

  /**
   * frontend filter, this is called after getFullTextFilters
   * @param entity
   */
  filterEntity(entity: Entity): boolean {
    const defaultFilter = super.filterEntity(entity);
    const lowercaseValue = this.value.toLowerCase();

    // default filter does not take into account the description frontend side
    const descriptionPresent = entity.metadata.description
      ?.toLowerCase()
      .includes(lowercaseValue);

    return descriptionPresent || defaultFilter;
  }

  /**
   * Delegate search to backend by passing the search terms and fields to search in.
   */
  getFullTextFilters(): { term: string; fields: string[] } {
    const customFilters = super.getFullTextFilters();
    customFilters.fields = [];
    customFilters.fields.push('metadata.name');
    customFilters.fields.push('metadata.tags');
    customFilters.fields.push('metadata.description');
    customFilters.fields.push('spec.mesh.name');
    customFilters.fields.push('spec.mesh.tags');
    return customFilters;
  }
}
